import React from "react";
import NavItem from "./NavItem";
import logo from "../img/L.Samuel.png"; // Import the logo image
import "../styles/Navbar.scss";

export default function Navbar(props) {
  const fade = "navigation fade-in center";

  return (
    <nav>
      <div className="navbar">
        <div className="logo">
          {/* Logo */}
          <img
            src={logo} // Use the imported logo variable
            alt="Logo"
            style={{ height: "20px" }}
          />
        </div>
        <ul className={props.home === "true" ? fade : ""}>
          {props.PAGES.map((page) => (
            <NavItem
              page={page.title}
              link={page.route}
              onNavClick={props.onNavClick}
              key={page.title}
              href={page.href}
            ></NavItem>
          ))}
        </ul>
      </div>
    </nav>
  );
}
