import React, { useState, useEffect } from "react";
import "./App.css";
import Header from "./components/Header";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import NavBar from "./components/NavBar";
import Projects from "./pages/Projects";
import Project1 from "./pages/Stepnow";
import Project2 from "./pages/Stepnow";
import "./styles/App.scss";

function App() {
  const PAGES = [
    { title: "WORK", route: "/", href: "#section-projects" },
    { title: "CONTACT", route: "/", href: "mailto:leahyjsamuel@gmail.com" },
  ];

  const [isFading, setIsFading] = useState(false);
  const [setFadingOneScreen, setIsFadingOneScreen] = useState(false);
  const [modal, setModal] = useState(false);
  const [isSubheadingVisible, setIsSubheadingVisible] = useState(true); // Initially set to true

  const handleNavClick = (page) => {};

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    const scrollTop = window.pageYOffset;
    const windowHeight = window.innerHeight;
    const threshold1 = 0;

    if (scrollTop > windowHeight) {
      setIsFadingOneScreen(true);
    } else {
      setIsFadingOneScreen(false);
    }

    if (scrollTop > windowHeight * threshold1) {
      setIsFading(true);
    } else {
      setIsFading(false);
    }
  };

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  if (isMobile) {
    return <div className="App">Please view this site on a web browser.</div>;
  }

  const homePage = (
    <div className={`homepage ${isFading || setFadingOneScreen ? "fade" : ""}`}>
      <NavBar PAGES={PAGES} onNavClick={handleNavClick}></NavBar>
      <Header />
      {isSubheadingVisible && (
        <div className="container_mouse">
          <span className="mouse-btn">
            <span className="mouse-scroll"></span>
          </span>
          <span>Scroll Down</span>
        </div>
      )}
    </div>
  );

  const projectsSection = (
    <div id="section-projects">
      <Projects />
    </div>
  );

  return (
    <div className="App">
      <header
        className={`App-header ${!!modal ? "modal-active" : ""}`}
        onClick={(e) => {
          if (modal && e.target.className === "modal-background")
            setModal("none");
        }}
      >
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <React.Fragment>
                  {homePage}
                  {projectsSection}
                </React.Fragment>
              }
            />
            <Route path="/step-now" element={<Project1 />} />
            <Route path="/project2" element={<Project2 />} />
            {/* Add more routes for other projects */}
          </Routes>
        </Router>
      </header>
      <footer className="App-footer">
        Designed and Coded by Leah Samuel 👩🏽‍💻 2024
      </footer>
    </div>
  );
}

export default App;
