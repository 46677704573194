import React, { useState, useEffect } from "react";

const Header = () => {
  const [text, setText] = useState("");
  const [showSubheading, setShowSubheading] = useState(false);
  const typingSpeed = 50; // Adjust typing speed here
  const fadeDelay = 500; // Adjust delay for subheading fade-in effect

  useEffect(() => {
    const content =
      "I am a Philosophy-driven UX Designer crafting user-centric solutions.";
    const totalLength = content.length;

    let timer;
    if (!showSubheading && totalLength === text.length) {
      // If typewriter animation is complete, trigger subheading fade-in effect
      setTimeout(() => {
        setShowSubheading(true);
      }, fadeDelay);
    }

    if (text.length < totalLength) {
      // Continue typewriter animation until text is fully typed
      timer = setTimeout(() => {
        setText((prevText) => content.substring(0, prevText.length + 1));
      }, typingSpeed);
    }

    return () => clearTimeout(timer);
  }, [text, showSubheading, typingSpeed, fadeDelay]);

  return (
    <div className="header-wrapper">
      <h1 className="heading">
        {text.split(" ").map((word, i) => (
          <span
            key={i}
            className={word === "Philosophy-driven" ? "gradient-text" : ""}
          >
            {word}{" "}
          </span>
        ))}
        <span className="cursor">|</span>
      </h1>
      <div className={`subheading ${showSubheading ? "fade-in" : ""}`}>
        <p
          style={{
            fontSize: "20px",
            fontWeight: "400",
            color: "#929292",
            maxWidth: "70vw",
          }}
        >
          Hey👋 I'm Leah, a UX Designer specialising in end-to-end solutions,
          with an MSc in Service Design.
        </p>
      </div>
      <style jsx>{`
        .gradient-text {
          background: linear-gradient(to right, #ff218c, #00a6ff);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          weight: 700;
          font-size: 72px;
        }
        .heading {
          display: inline-block;
          margin-left: 1rem;
          margin-bottom: 1rem;
          weight: 400;
          font-size: 64px;
          max-width: 70vw;
        }
        .cursor {
          animation: blink-caret 0.75s step-end infinite;
        }
        .subheading {
          opacity: 0;
          transition: opacity 0.5s ease-in-out;
          margin-top: 0.5rem; /* Adjust margin as needed */
          text-align: left; /* Align subheading to the left */
          margin: 1rem;
        }

        .header-wrapper {
          text-align: left;
          margin-left: 10rem;
        }
        .fade-in {
          opacity: 1;
        }
        @keyframes blink-caret {
          from,
          to {
            border-color: transparent;
          }
          50% {
            border-color: #000;
          }
        }
      `}</style>
    </div>
  );
};

export default Header;
