import React from "react";
import stepnow from "../img/stepnow.png";
import "../styles/Stepnow.scss";

const StepNow = () => {
  const handleGoBack = () => {
    // Handle go back functionality
    console.log("Go back clicked");
  };

  return (
    <div className="step-now-container">
      {/* Back button */}
      <div
        className="section-back"
        style={{
          position: "absolute",
          top: "20px",
          left: "20px",
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d",
          opacity: 1,
        }}
      >
        <a
          data-w-id="68730f40-0368-57e9-0865-8904ebd1b022"
          href="/"
          className="button-project-back w-inline-block"
          onClick={handleGoBack}
        >
          <img
            src="https://assets-global.website-files.com/63dcb6e1a80e9454b630f4c4/645b5e5d57552962d27c0020_icon-back-2.svg"
            loading="lazy"
            alt=""
            className="icon-back-to-home"
            style={{
              transform:
                "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              transformStyle: "preserve-3d",
            }}
          />
          <div className="text-button" style={{ color: "white" }}>
            Back
          </div>
        </a>
      </div>

      {/* Main Content */}
      <img className="stepnow" src={stepnow} alt="" />
    </div>
  );
};

export default StepNow;
