import React from "react";
import { Link } from "react-router-dom";

const Project = ({ imageUrl, alt, tags, linkTo, name }) => {
  return (
    <div className="project">
      <Link to={linkTo} className="image-link">
        {" "}
        {/* Wrap the image in a Link component */}
        <img src={imageUrl} alt={alt} className="project-image" />
      </Link>
      <div className="hover-text">{name}</div>
      <div className="tags">
        {tags.map((tag, index) => (
          <button key={index}>{tag}</button>
        ))}
      </div>
    </div>
  );
};

export default Project;
