import React from "react";
import Project from "../components/Project";
import "../styles/Projects.scss";
import Stepnow from "./Stepnow";
import stepnowcover from "../img/stepnowcover.png";
import project2 from "../img/project2.png";

const Projects = () => {
  return (
    <div className="projects">
      <Project
        imageUrl={stepnowcover}
        alt="Project 1"
        tags={["UX Design", "Design System", "Design Thinking"]}
        linkTo="/step-now" // Specify the destination URL for Project 1
        name="Step Now"
      />
      <Project
        imageUrl={project2}
        alt="Project 2"
        tags={["UX Design", "Research", "Design Concept"]}
        //linkTo="/project2" // Specify the destination URL for Project 2
      />
      {/* <Project
        imageUrl="https://images.ctfassets.net/4cd45et68cgf/FJLXrcHViY5jAZYJLfXeg/22bb24c10cab4070c92806bbc8935379/Netflix_Games.png?w=2000"
        alt="Project 3"
        tags={["Design Systems"]}
        linkTo="/project3" // Specify the destination URL for Project 3
      /> */}
      {/* <Project
        imageUrl="https://images.ctfassets.net/4cd45et68cgf/FJLXrcHViY5jAZYJLfXeg/22bb24c10cab4070c92806bbc8935379/Netflix_Games.png?w=2000"
        alt="Project 4"
        tags={["Design Thinking", "Research", "UX Design"]}
        linkTo="/project4" // Specify the destination URL for Project 4
      /> */}
    </div>
  );
};

export default Projects;
