import React from "react";
import { NavLink } from "react-router-dom";

export default function NavItem(props) {
  let page = null;
  if (props.page === "HOME") {
    page = "bg";
  }

  const handleClick = (e) => {
    e.preventDefault();
    if (props.href.startsWith("mailto")) {
      window.location.href = props.href;
    } else {
      const section = document.querySelector(props.href);
      section.scrollIntoView({ behavior: "smooth" });
      props.onNavClick(page);
    }
  };

  return (
    <li className="navigationItem">
      <NavLink
        activeClassName="active"
        exact
        to={props.link}
        onClick={handleClick}
      >
        {props.page}
      </NavLink>
    </li>
  );
}
